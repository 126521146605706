@import "../../node_modules/source-sans-pro/source-sans-pro.css";

#app {
  &.application,
  .title,
  .headline,
  .display-1,
  .display-2,
  .display-3,
  .display-4,
  .v-card__title {
    font-family: 'Source Sans Pro', 'Roboto', sans-serif !important;
  }

  .v-icon {
    user-select: none;
  }
}

// Add support for font-weight 500

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  src:
    url('../../node_modules/source-sans-pro/WOFF2/TTF/SourceSansPro-Semibold.ttf.woff2') format('woff2'),
    url('../../node_modules/source-sans-pro/WOFF/OTF/SourceSansPro-Semibold.otf.woff') format('woff'),
    url('../../node_modules/source-sans-pro/OTF/SourceSansPro-Semibold.otf') format('opentype'),
    url('../../node_modules/source-sans-pro/TTF/SourceSansPro-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  src:
    url('../../node_modules/source-sans-pro/WOFF2/TTF/SourceSansPro-SemiboldIt.ttf.woff2') format('woff2'),
    url('../../node_modules/source-sans-pro/WOFF/OTF/SourceSansPro-SemiboldIt.otf.woff') format('woff'),
    url('../../node_modules/source-sans-pro/OTF/SourceSansPro-SemiboldIt.otf') format('opentype'),
    url('../../node_modules/source-sans-pro/TTF/SourceSansPro-SemiboldIt.ttf') format('truetype');
}
