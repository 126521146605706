$mdi-font-path: "../../node_modules/@mdi/font/fonts";

@import "~@mdi/font/scss/materialdesignicons";
@import "fonts";

html, body, #app {
  background-color: #fafafa;
  height: 100%;
  width: 100%;
}

.v-content__wrap {
  overflow: auto !important;
}

// Body first animation
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#app {
  animation: 0.15s ease-in-out 0s 1 fadeIn !important;
}

a {
  outline: none;
}
.disable-hover tr:hover {
  background-color: transparent !important;
}